import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { FormConfirmService } from "@app/shared/services/form-comfirm.service";
import { Subscription } from "rxjs";

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: "app-global-form-tabs",
  templateUrl: "./global-form-tabs.component.html"
})
export class GlobalFormTabsComponent {
  private formConfirmService = inject(FormConfirmService);
  public subscribers: Subscription[] = [];
  constructor() {
    const sub = this.formConfirmService.formConfirmObjt.subscribe(val => {
      if (val.moveToNextTab) {
        this.tabSelected = val.nextTab;
        this.tabSelectedChange.emit(val.nextTab);
        //reset services
        this.formConfirmService.resetState();
      }
    });
    this.subscribers.push(sub);
  }

  ngOnDestroy(): void {
    this.subscribers.forEach(each => each.unsubscribe());
  }

  @Input()
  public tabs: string[] = [];

  @Input()
  public tabSelected: string = "";

  @Output()
  public tabSelectedChange: EventEmitter<string> = new EventEmitter();

  public selectTab(tab: string) {
    if (this.formConfirmService.formConfirmObjt.value.isFormDirty) {
      this.formConfirmService.formConfirmObjt.next({
        ...this.formConfirmService.formConfirmObjt.value,
        askFormSubmit: true,
        nextTab: tab,
      });
    } else {
      this.tabSelected = tab;
      this.tabSelectedChange.emit(tab);
    }
  }

  public isTabActive(tab: string): boolean {
    return tab === this.tabSelected;
  }
}
